export default function() {
  return {
    BCA: {
      bankDataId: 'BOI-BCA',
      bankName: 'Bank of Ireland (UK)',
      bankAccountName: 'Business Current Account',
      currentBankingFeeAmount: '',
      bankingFeeAmountSave: '',
      accountHighlights: ['Business on Line', '365 Digital and Phone Banking', '15 Minute loan applications', '365 Digital and Phone Banking'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: {
          amount: '£25',
          description: 'per quarter'
        },
        averageDaysToOpenAnAccountFee: '10',
        averageDaysToReceiveADebitCard: '11',
        averageDaysToSetupInternetBanking: '4',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: false,
        googlePay: false,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: {
        inBranch: true,
        online: true,
        telephone: true,
        postOffice: true,
        mobileApp: true
      },
      additionalWaysToBank: {
        description: [
          {
            header: 'Business On Line',
            body:
              'Business On Line is a comprehensive, secure and accessible service designed to meet the increasing electronic requirements of our business customers.Save time by paying salaries or suppliers at once, with only one transaction on the account.'
          },
          {
            header: 'Business loans made easier',
            body: 'Apply in just 15 minutes with loan approval within 48 hours. Over 8 out of 10 applications approved.'
          },
          {
            header: '365 Digital and Phone Banking',
            body:
              'Anytime account access via a range of devices including smartphone, tablet and computer. Save on paperwork with a 12 month view of past transactions (13 months for Credit Card). Save time and money by making online transfers instead of paying by cash and cheque'
          },
          {
            header: '',
            body: 'Banking at the Post Office - Fulfil your needs in a quick and convenient way.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: '',
            body: 'At Bank of Ireland UK we have a dedicated team of professionals to deal with all of your banking needs.'
          },
          {
            header: '',
            body: 'A simple pricing plan to allow you to control how much you spend on transaction fees'
          },
          {
            header: '',
            body: 'Quarterly advice note detailing any transaction charges payable'
          },
          {
            header: '',
            body: 'Subject to agreement, a business overdraft to help manage your cashflow'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 45,
        onlineAndMobileBankingService: 52,
        smeOverdraftAndLoanServices: 45,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 52,
        serviceInBranchesAndBusinessCenters: 55,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: 'Free'
          },
          {
            name: 'StandingOrder',
            value: 'Free'
          },
          {
            name: 'CardPurchaseDomestic',
            value: 'Free'
          },
          {
            name: 'CardPurchaseForeign',
            tooltip: '2.75% non-sterling transaction fee',
            info: true
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: 'in EEA - 2.75% non-sterling cash fee. Outside EEA - £1.50 per item & 2.75% NSTF ',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: '95p'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            tooltip: '£0.80 per item & 0.9 per £100',
            info: true
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: '£0.80 per item & 0.9 per £100',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            tooltip: '0 if in EEA, £15 for other international payments',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: '£21 for EEA Same day, £25 for other international payments',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£40 per quarter (includes Business On Line)',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: '£0.80 per item & 0.9 per £100',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: '£0.80 per item & 0.9 per £100',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Free for domestic transfers and £15 per item for foreign transfers',
            info: true
          }
        ],
        totalSavings: -71.66
      },
      links: {
        openAccount: 'https://www.bankofirelanduk.com/business/business-current-accounts/business-current-account/how-to-apply/',
        switchAccount: 'https://www.bankofirelanduk.com/business/switch-to-bank-of-ireland-uk/'
      }
    }
  }
}
