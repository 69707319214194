import dictionary from '@/config/dictionary'

export default function () {
  return {
    EPAY: {
      bankDataId: 'BARCLAYS-EPAY',
      bankName: 'Barclays',
      bankAccountName: `e-Payments (Up to £400k ${dictionary.turnover})`,
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 10.5,
      accountHighlights: ['Due to Covid-19 account applications are currently taking 6 weeks+ to review'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£6.50', description: 'per month' },
        averageDaysToOpenAnAccountFee: '8',
        averageDaysToReceiveADebitCard: '4',
        averageDaysToSetupInternetBanking: '5',
        dedicatedRelationshipManager: true,
        overdraftFacility: true,
        overdraftLimited: 50000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: false,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'SmartBusiness Dashboard',
            body: 'Bring together key information from your business banking accounts and apps to help save time and simplify your admin'
          },
          {
            header: 'International Team',
            body:
              "Whether you're importing or exporting, starting out or expanding further, we have the tools and services to help your business grow. It's easy to make international payments through online banking, or from the Barclays app"
          },
          {
            header: 'High Growth and Entrepreneur experts',
            body:
              "Whether you're seeking practical tools, expert knowledge, connections or inspiration, our innovative approach means you don't just bank with us - you bank on your future"
          },
          {
            header: 'Comprehensive guides',
            body: 'Our range of guides can help with everything from business structures to finding funding'
          },
          {
            header: '',
            body:
              'Barclays Bank UK PLC is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority (Financial Services Register Number: 759676). Registered in England. Registered Number 9740322. Registered Office: 1 Churchill Place, London E14 5HP. Barclays Bank UK PLC is covered by the Financial Ombudsman Service (UK branches only). '
          },
          {
            header: '',
            body: 'Lending is subject to application and status.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: '',
            body:
              'Our UK based Everyday Banking team is here to help with your day-to-day banking, while our UK-based Business Direct team is on hand to help you manage your business'
          },
          { header: '', body: 'Barclays Invoicing lets you send, track and reconcile invoices for free using the Barclays app' },
          {
            header: 'Loyalty Reward',
            body: 'We pay you back a proportion of the charges we apply to your current account on a monthly basis. It’s our way of rewarding you for being our customer'
          },
          {
            header: 'Online Banking',
            body: "As well as easy everyday banking, you'll find lots of additional features to help make running your business smoother, like group payments and secure messages"
          },
          { header: 'Mobile Banking', body: 'Use the Barclays app to run your accounts from the palm of your hand. Manage accounts, invoice on the move and get instant help' },
          { header: 'Lending', body: "Whether you're managing your cash flow or need funding to fuel growth, we offer a range of lending options." }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 59,
        onlineAndMobileBankingService: 73,
        smeOverdraftAndLoanServices: 61,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 59,
        serviceInBranchesAndBusinessCenters: 52,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: 'Free'
          },
          {
            name: 'StandingOrder',
            value: 'Free'
          },
          {
            name: 'CardPurchaseDomestic',
            value: 'Free'
          },
          {
            name: 'CardPurchaseForeign',
            value: '2.75%'
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '£1.5 plus 2.75% per item',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: '£1.5'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            tooltip: '£1.5 per £100',
            info: true
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: '£1.5 per £100',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            tooltip: 'BIPS Standard (Branch or Telephone) £25 per item',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: 'BIPS Priority (branch/phone) - £40, via online is £15; SEPA Manual (branch/phone) - £1.5; SEPA Digital (Online/ Mobile/ Barclays.NET) - £0',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£6.5 per month',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: '£1.5 per £100 per deposit',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: '£1.5 per £100 per deposit',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: Free, Foreign: 0 for Euro payments from EU/EEA countries, if different currency or from outside the EU/EEA it will be £6 per item',
            info: true
          }
        ],
        totalSavings: 10.5
      },
      links: {
        openAccount: '/banks/switch-barclays',
        switchAccount: '/banks/switch-barclays'
      }
    },
    EPAYPL: {
      bankDataId: 'BARCLAYS-EPAYPL',
      bankName: 'Barclays',
      bankAccountName: `e-Payments (£400k-£6.5m ${dictionary.turnover})`,
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 10.5,
      accountHighlights: ['Due to Covid-19 account applications are currently taking 6 weeks+ to review'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£6.50', description: 'per month' },
        averageDaysToOpenAnAccountFee: '8',
        averageDaysToReceiveADebitCard: '4',
        averageDaysToSetupInternetBanking: '5',
        dedicatedRelationshipManager: true,
        overdraftFacility: true,
        overdraftLimited: 50000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: false,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'SmartBusiness Dashboard',
            body: 'Bring together key information from your business banking accounts and apps to help save time and simplify your admin'
          },
          {
            header: 'International Team',
            body:
              "Whether you're importing or exporting, starting out or expanding further, we have the tools and services to help your business grow. It's easy to make international payments through online banking, or from the Barclays app"
          },
          {
            header: 'High Growth and Entrepreneur experts',
            body:
              "Whether you're seeking practical tools, expert knowledge, connections or inspiration, our innovative approach means you don't just bank with us - you bank on your future"
          },
          {
            header: 'Comprehensive guides',
            body: 'Our range of guides can help with everything from business structures to finding funding'
          },
          {
            header: '',
            body:
              'Barclays Bank UK PLC is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority (Financial Services Register Number: 759676). Registered in England. Registered Number 9740322. Registered Office: 1 Churchill Place, London E14 5HP. Barclays Bank UK PLC is covered by the Financial Ombudsman Service (UK branches only).'
          },
          {
            header: '',
            body: 'Lending is subject to application and status.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: '',
            body: `Dedicated Business Manager. If your annual ${dictionary.turnover} is over £400K you’ll have your own Relationship Manager whose business banking expertise could help you gain an edge over competitors. They’ll act as a trusted partner to your business, as you manage, sustain and grow it. You can meet wherever’s convenient to you`
          },
          { header: '', body: 'Our UK based Everyday Banking team is here to help with your day-to-day banking needs' },
          {
            header: 'Online Banking',
            body: 'As well as easy everyday banking, you’ll find lots of additional features to help make running your business smoother, like group payments and secure messages'
          },
          { header: 'Mobile Banking', body: 'Use the Barclays app to run your accounts from the palm of your hand. Manage accounts, invoice on the move and get instant help' },
          { header: 'A range of flexible lending options', body: "Whether you're managing your cashflow or need funding to fuel growth, we offer a range of lending options" },
          {
            header: 'Loyalty Reward',
            body: 'We pay you back a proportion of the charges we apply to your current account on a monthly basis. It’s our way of rewarding you for being our customer'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 59,
        onlineAndMobileBankingService: 73,
        smeOverdraftAndLoanServices: 61,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 59,
        serviceInBranchesAndBusinessCenters: 52,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: 'Free'
          },
          {
            name: 'StandingOrder',
            value: 'Free'
          },
          {
            name: 'CardPurchaseDomestic',
            value: 'Free'
          },
          {
            name: 'CardPurchaseForeign',
            value: '2.75%'
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '£1.5 plus 2.75% per item',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: '£1.5'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            tooltip: '£1.5 per £100',
            info: true
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: '£1.5 per £100',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            tooltip: 'BIPS Standard (Branch or Telephone) £25 per item',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: 'BIPS Priority (branch/phone) - £40, via online is £15; SEPA Manual (branch/phone) - £1.5; SEPA Digital (Online/ Mobile/ Barclays.NET) - £0',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£6.5 per month',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: '£1.5 per £100 per deposit',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: '£1.5 per £100 per deposit',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: Free, Foreign: 0 for Euro payments from EU/EEA countries, if different currency or from outside the EU/EEA it will be £6 per item',
            info: true
          }
        ],
        totalSavings: 10.5
      },
      links: {
        openAccount: '/banks/switch-barclays',
        switchAccount: '/banks/switch-barclays'
      }
    }
  }
}
