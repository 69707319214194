export default function() {
  return {
    BCA: {
      bankDataId: 'LLOYDS-BCA',
      bankName: 'Lloyds',
      bankAccountName: 'Lloyds Bank Switcher',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 60.55,
      accountHighlights: ['Lloyds Bank have temporarily stopped accepting business account applications from customers looking to switch their banking'],
      accountFeatures: {
        freeBankingPeriod: '6',
        accountFee: { amount: 'Free', description: 'for the first 6 months, then £6.50 per month' },
        averageDaysToOpenAnAccountFee: '4',
        averageDaysToReceiveADebitCard: '5',
        averageDaysToSetupInternetBanking: '5',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: false,
        googlePay: false,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'Inter-bank agency agreement',
            body:
              'Inter-bank agency agreements are arrangements between banks, which provide a range of branch counter services to another bank’s customers by prior arrangement. They provide a convenient service to business customers who find accessing their own bank’s branches difficult.'
          },
          {
            header: 'Cardnet',
            body:
              'Accept card payments online, face-to-face and over the phone. Foreign currency conversion for overseas customers. Easily track payments with sophisticated online reporting tools.'
          },
          {
            header: 'Cardless Immediate Deposit Machines (IDM)',
            body:
              'We will encourage the use of our new Cardless Immediate Deposit Machines, which allow you to make deposits of up to 50 notes and 5 cheques without a physical card present.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Simple pricing and free electronic payments.',
            body:
              "A guaranteed switch in 7 working days, from when your account is opened using the Current Account Switch Service. 6 months' free day-to-day business banking when you switch*. Dedicated support from our UK-based business management team."
          },
          {
            header: '',
            body: '*Six months’ free day-to-day business banking for customers when switching from a business account held elsewhere. Free business banking includes cheques, standing orders, cash, UK Sterling Direct Debits, deposits and withdrawals. All we ask is that you operate your account in credit or within agreed limits. Free electronic payments include UK Sterling Direct Debits, Standing Orders, Debit Card Transactions, Lloyds Bank/Other Bank ATM Withdrawals, Internet/Phonebank Debits/Credits, Faster Payment Debits/Credits, Internet Bulk Payments and Automated Credits.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 56,
        onlineAndMobileBankingService: 68,
        smeOverdraftAndLoanServices: 59,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 57,
        serviceInBranchesAndBusinessCenters: 61,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          { name: 'DirectDebit', value: 'Free' },
          { name: 'StandingOrder', value: 'Free' },
          { name: 'CardPurchaseDomestic', value: 'Free' },
          { name: 'CardPurchaseForeign', value: 'Free' },
          { name: 'ATMWithdrawalDomestic', value: 'Free' },
          {
            name: 'ATMWithdrawalForeign',
            tooltip:
              '2.75% non-sterling transaction fee; the total converted sterling value then incurs a 1.5% nonsterling cash transaction fee (minimum £1.50). The 1.5% (minimum £1.50) non-sterling cash transaction fee is not charged for withdrawals of euro within the EEA. If you use another bank’s ATM, some providers may charge you a fee for using their machines.',
            info: true
          },
          { name: 'ChequePaymentOut', value: '65p' },
          { name: 'CounterCashWithdrawalBranch', tooltip: '£1 (per £100) for the first  £1,500 per month  £0.80 (per £100) for anything  over £1,500 per month', info: true },
          { name: 'CounterCashWithdrawalPostOffice', tooltip: '£1 (per £100) for the first  £1,500 per month  £0.80 (per £100) for anything  over £1,500 per month', info: true },
          { name: 'TransferDomestic', value: 'Free' },
          { name: 'TransferDomesticFast', value: 'Free' },
          {
            name: 'TransferForeign',
            tooltip: '£15 or £5 - International Payment via Internet Banking £15 (Faster Payment). Basic Euro Moneymover (SEPA)* Credit Transfer £5',
            info: true
          },
          { name: 'TransferForeignFast', value: '£15' },
          { name: 'AccountMaintenanceFee', value: '£6.50', tooltip: 'Free for the first 6 months, then £6.50 per month', info: true },
          { name: 'CounterCashDepositBranch', tooltip: '£1 (per £100) for the first  £1,500 per month  £0.80 (per £100) for anything  over £1,500 per month', info: true },
          { name: 'CounterCashDepositPostOffice', tooltip: '£1 (per £100) for the first  £1,500 per month  £0.80 (per £100) for anything  over £1,500 per month', info: true },
          { name: 'DomesticTransfersIn', value: 'Free' },
          { name: 'ForeignTransfersIn', tooltip: 'Up to £100 is charged at £2 Over £100 is charged at £7 Fee is not change for Euro transactions within the EEA', info: true }
        ],
        totalSavings: 60.55
      },
      links: {
        openAccount: 'https://businessonboarding.lloydsbank.co.uk/splash',
        switchAccount:
          'https://www.lloydsbank.com/business/landing-pages/bcaswitcher.html?utm_source=affiliate&utm_medium=swoop&utm_campaign=lloyds_business_account&utm_content=switcher'
      }
    }
  }
}
