export default function() {
  return {
    FRE: {
      bankDataId: 'REVOLUT-FRE',
      bankName: 'Revolut',
      bankAccountName: 'Free',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 12.15,
      accountHighlights: ['Integrate with business software', 'Digitised corporate cards', 'FX at Interbank Rate', 'Payments schedule and status overview'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£0', description: 'per month' },
        averageDaysToOpenAnAccountFee: 'N/A',
        averageDaysToReceiveADebitCard: 'N/A',
        averageDaysToSetupInternetBanking: 'N/A',
        dedicatedRelationshipManager: false,
        overdraftFacility: false,
        overdraftLimited: 0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: false,
        chargeCard: false,
        cheque: false,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: false, online: true, telephone: false, postOffice: false, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'Send and exchange money wihtout hidden fees',
            body: 'Easily exchange money in up to 28 currencies at the interbank exchange rate, all from the palm of your hand. Cut unnecessary costs and save money for growth.'
          },
          {
            header: 'Manage payments the hassle-free way',
            body:
              'Staying in control of your business finances has never been easier. See a payments overview with schedule and status information, and execute everything from one place'
          },
          {
            header: 'Save time with Bulk Payments',
            body: 'Pay multiple invoices or run payroll using bulk payments. Make multi-currency payments to different countries in one batch'
          },
          {
            header: 'Get rid of routine work with automated payments',
            body:
              "No more forgotten or late payments when you set up scheduled payments in your account. Automatically notify recipients that you've sent a payment, with estimated pay out date"
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Connect your apps to simplify work with Revolut Connect',
            body:
              'Revolut Connect allows you to connect and integrate in just a click with popular business software that you already use to simplify and streamline your workflow.'
          },
          {
            header: 'Radically better corporate cards',
            body:
              'Easily track transactions and control cards activity by employees, including automated notifications. Set monthly limits and get statements with transactions for each card separately'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 0,
        onlineAndMobileBankingService: 0,
        smeOverdraftAndLoanServices: 0,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 0,
        serviceInBranchesAndBusinessCenters: 0,
        description: 'No data available as Revolut was not involved in the survey',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          { name: 'DirectDebit', tooltip: 'first 5 transfers free, then 0.2 per item', info: true },
          { name: 'StandingOrder', tooltip: 'first 5 transfers free, then 0.2 per item', info: true },
          { name: 'CardPurchaseDomestic', value: 'Free' },
          { name: 'CardPurchaseForeign', value: 'Free' },
          { name: 'ATMWithdrawalDomestic', value: '2%' },
          { name: 'ATMWithdrawalForeign', value: '2%' },
          { name: 'ChequePaymentOut', value: 'N/A' },
          { name: 'CounterCashWithdrawalBranch', value: 'N/A' },
          { name: 'CounterCashWithdrawalPostOffice', value: 'N/A' },
          { name: 'TransferDomestic', tooltip: 'first 5 transfers free, then 0.2 per item', info: true },
          { name: 'TransferDomesticFast', tooltip: 'first 5 transfers free, then 0.2 per item', info: true },
          { name: 'TransferForeign', value: '£3' },
          { name: 'TransferForeignFast', value: '£3' },
          { name: 'AccountMaintenanceFee', value: 'Free' },
          { name: 'CounterCashDepositBranch', value: 'N/A' },
          { name: 'CounterCashDepositPostOffice', value: 'N/A' },
          { name: 'DomesticTransfersIn', tooltip: 'first 5 transfers free, then 0.2 per item', info: true },
          { name: 'ForeignTransfersIn', value: '£3' }
        ],
        totalSavings: 12.15
      },
      links: {
        openAccount: 'http://revolutforbusiness.ngih.net/y3yx2',
        switchAccount: 'http://revolutforbusiness.ngih.net/y3yx2'
      }
    },
    GRW: {
      bankDataId: 'REVOLUT-GRW',
      bankName: 'Revolut',
      bankAccountName: 'Grow',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -165.25,
      accountHighlights: ['Integrate with business software', 'Digitised corporate cards', 'FX at Interbank Rate', 'Payments schedule and status overview'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£25', description: 'per month' },
        averageDaysToOpenAnAccountFee: 'N/A',
        averageDaysToReceiveADebitCard: 'N/A',
        averageDaysToSetupInternetBanking: 'N/A',
        dedicatedRelationshipManager: false,
        overdraftFacility: false,
        overdraftLimited: 0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: false,
        chargeCard: false,
        cheque: false,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: false, online: true, telephone: false, postOffice: false, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'Send and exchange money wihtout hidden fees',
            body: 'Easily exchange money in up to 28 currencies at the interbank exchange rate, all from the palm of your hand. Cut unnecessary costs and save money for growth.'
          },
          {
            header: 'Manage payments the hassle-free way',
            body:
              'Staying in control of your business finances has never been easier. See a payments overview with schedule and status information, and execute everything from one place'
          },
          {
            header: 'Save time with Bulk Payments',
            body: 'Pay multiple invoices or run payroll using bulk payments. Make multi-currency payments to different countries in one batch'
          },
          {
            header: 'Get rid of routine work with automated payments',
            body:
              "No more forgotten or late payments when you set up scheduled payments in your account. Automatically notify recipients that you've sent a payment, with estimated pay out date"
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Connect your apps to simplify work with Revolut Connect',
            body:
              'Revolut Connect allows you to connect and integrate in just a click with popular business software that you already use to simplify and streamline your workflow.'
          },
          {
            header: 'Radically better corporate cards',
            body:
              'Easily track transactions and control cards activity by employees, including automated notifications. Set monthly limits and get statements with transactions for each card separately'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 0,
        onlineAndMobileBankingService: 0,
        smeOverdraftAndLoanServices: 0,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 0,
        serviceInBranchesAndBusinessCenters: 0,
        description: 'No data available as Revolut was not involved in the survey',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          { name: 'DirectDebit', tooltip: 'first 100 transfers free, then 0.2 per item', info: true },
          { name: 'StandingOrder', tooltip: 'first 100 transfers free, then 0.2 per item', info: true },
          { name: 'CardPurchaseDomestic', value: 'Free' },
          { name: 'CardPurchaseForeign', value: 'Free' },
          { name: 'ATMWithdrawalDomestic', value: '2%' },
          { name: 'ATMWithdrawalForeign', value: '2%' },
          { name: 'ChequePaymentOut', value: 'N/A' },
          { name: 'CounterCashWithdrawalBranch', value: 'N/A' },
          { name: 'CounterCashWithdrawalPostOffice', value: 'N/A' },
          { name: 'TransferDomestic', tooltip: 'first 100 transfers free, then 0.2 per item', info: true },
          { name: 'TransferDomesticFast', tooltip: 'first 100 transfers free, then 0.2 per item', info: true },
          { name: 'TransferForeign', tooltip: 'first 10 transfers free, then £3 per item', info: true },
          { name: 'TransferForeignFast', tooltip: 'first 10 transfers free, then £3 per item', info: true },
          { name: 'AccountMaintenanceFee', value: '£25' },
          { name: 'CounterCashDepositBranch', value: 'N/A' },
          { name: 'CounterCashDepositPostOffice', value: 'N/A' },
          { name: 'DomesticTransfersIn', tooltip: 'first 100 transfers free, then 0.2 per item', info: true },
          { name: 'ForeignTransfersIn', tooltip: 'first 100 transfers free, then 0.2 per item', info: true }
        ],
        totalSavings: -165.25
      },
      links: {
        openAccount: 'http://revolutforbusiness.ngih.net/y3yx2',
        switchAccount: 'http://revolutforbusiness.ngih.net/y3yx2'
      }
    }
  }
}
