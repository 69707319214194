import { render, staticRenderFns } from "./static-bank-details.vue?vue&type=template&id=0e70fe72&scoped=true&"
import script from "./static-bank-details.vue?vue&type=script&lang=js&"
export * from "./static-bank-details.vue?vue&type=script&lang=js&"
import style0 from "./static-bank-details.vue?vue&type=style&index=0&id=0e70fe72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e70fe72",
  null
  
)

export default component.exports