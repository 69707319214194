export default function() {
  return {
    BDPA: {
      bankDataId: 'COOP-BDPA',
      bankName: 'The Co-operative Bank',
      bankAccountName: 'Business Directplus (balance above £1k at all times)',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 90.0,
      accountHighlights: ['Best Service from a Business Bank', 'Best Charity Banking Provider', 'Merchant Services', 'Manage your finances 24/7'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '', description: '' },
        averageDaysToOpenAnAccountFee: '11',
        averageDaysToReceiveADebitCard: '4',
        averageDaysToSetupInternetBanking: '6',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: true,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'Online banking puts you in control of your account, allowing you to:',
            body: 'Check your balance and transactions, View Statements, Make Payments and Transfers and Order Stationery.'
          },
          {
            header: 'You can also use the Post Office® network for some banking actions:',
            body: 'Pay in cash and cheques and Exchange notes for coins (sometimes known as ‘change fulfilment’)'
          },
          {
            header: 'Telephone Banking',
            body:
              'If you don’t have access to your online banking, you can call our service advisors. Lines are open Monday - Friday 8.00am - 8.00 pm, 9.00am - 12noon Saturdays. You can access our voice automated service at all times, on the same number.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          { header: '', body: 'New Co-operative Bank business customers get free everyday banking for 30 months' },
          { header: '', body: 'No charges for all automated credits and cash withdrawals. Deposit £2,000 in cash and 100 cheques per month for free.' },
          {
            header: '',
            body: "The Co-operative Bank has been voted the winner of the 2019 'Best Charity Banking Provider' award by Business Moneyfacts, for the fourth consecutive year."
          },
          {
            header: '',
            body: "The Co-operative Bank has been voted the winner of the 2019 'Best Service from a Business Bank' award by Business Moneyfacts, for the third consecutive year."
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 48,
        onlineAndMobileBankingService: 43,
        smeOverdraftAndLoanServices: 0,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 46,
        serviceInBranchesAndBusinessCenters: 43,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: 'Free'
          },
          {
            name: 'StandingOrder',
            value: 'Free'
          },
          {
            name: 'CardPurchaseDomestic',
            value: 'Free'
          },
          {
            name: 'CardPurchaseForeign',
            value: '2.75%'
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '2.75% NSTF plus 2% cash fee (min £2)',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            tooltip: 'First 30 cheques per month are free, then 30p per item',
            info: true
          },
          {
            name: 'CounterCashWithdrawalBranch',
            value: 'Free'
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            value: 'Free'
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            tooltip: '£8 for SEPA or USD payments, for other international payments uses SWIFT at 0.25% of value (min £13, max £35).',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: 'Real time Euro payment is £25, others are via SWIFT at 0.25% of value (min £13, max £35).',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            value: 'Free'
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: 'Free for first £2000/month, then 75p per £100 above this',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: 'Free for first £2000/month, then 75p per £100 above this',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: Free, Foreign: Free per item if value is less than £100, free if over £100 but either euro or Swedish Krona, £6 per item if more than £100',
            info: true
          }
        ],
        totalSavings: 90.0
      },
      links: {
        openAccount: 'https://www.co-operativebank.co.uk/business/products/current-accounts/business-directplus',
        switchAccount: 'https://www.co-operativebank.co.uk/business/products/current-accounts/business-directplus'
      }
    },
    BDPB: {
      bankDataId: 'COOP-BDPB',
      bankName: 'The Co-operative Bank',
      bankAccountName: 'Business Directplus (balance below £1k at any time)',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -730.2,
      accountHighlights: ['Best Service from a Business Bank', 'Best Charity Banking Provider', 'Merchant Services', 'Manage your finances 24/7'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '', description: '' },
        averageDaysToOpenAnAccountFee: '11',
        averageDaysToReceiveADebitCard: '4',
        averageDaysToSetupInternetBanking: '6',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: true,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'Online banking puts you in control of your account, allowing you to:',
            body: 'Check your balance and transactions, View Statements, Make Payments and Transfers and Order Stationery.'
          },
          {
            header: 'You can also use the Post Office® network for some banking actions:',
            body: 'Pay in cash and cheques and Exchange notes for coins (sometimes known as ‘change fulfilment’)'
          },
          {
            header: 'Telephone Banking',
            body:
              'If you don’t have access to your online banking, you can call our service advisors. Lines are open Monday - Friday 8.00am - 8.00 pm, 9.00am - 12noon Saturdays. You can access our voice automated service at all times, on the same number.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          { header: '', body: 'New Co-operative Bank business customers get free everyday banking for 30 months' },
          { header: '', body: 'No charges for all automated credits and cash withdrawals. Deposit £2,000 in cash and 100 cheques per month for free.' },
          {
            header: '',
            body: "The Co-operative Bank has been voted the winner of the 2019 'Best Charity Banking Provider' award by Business Moneyfacts, for the fourth consecutive year."
          },
          {
            header: '',
            body: "The Co-operative Bank has been voted the winner of the 2019 'Best Service from a Business Bank' award by Business Moneyfacts, for the third consecutive year."
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 48,
        onlineAndMobileBankingService: 43,
        smeOverdraftAndLoanServices: 0,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 46,
        serviceInBranchesAndBusinessCenters: 43,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: '70p'
          },
          {
            name: 'StandingOrder',
            value: '70p'
          },
          {
            name: 'CardPurchaseDomestic',
            value: '70p'
          },
          {
            name: 'CardPurchaseForeign',
            value: '2.75%'
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '2.75% NSTF plus 2% cash fee (min £2)',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: '70p'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            value: 'Free'
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            value: 'Free'
          },
          {
            name: 'TransferDomestic',
            value: '70p'
          },
          {
            name: 'TransferDomesticFast',
            value: '70p'
          },
          {
            name: 'TransferForeign',
            tooltip: '£8 for SEPA or USD payments, for other international payments uses SWIFT at 0.25% of value (min £13, max £35).',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: 'Real time Euro payment is £25, others are via SWIFT at 0.25% of value (min £13, max £35).',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£5 per month',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: 'Free for first £2000/month, then 75p per £100 above this',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: 'Free for first £2000/month, then 75p per £100 above this',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: Free, Foreign: Free per item if value is less than £100, free if over £100 but either euro or Swedish Krona, £6 per item if more than £100',
            info: true
          }
        ],
        totalSavings: -730.2
      },
      links: {
        openAccount: 'https://www.co-operativebank.co.uk/business/products/current-accounts/business-directplus',
        switchAccount: 'https://www.co-operativebank.co.uk/business/products/current-accounts/business-directplus'
      }
    }
  }
}
