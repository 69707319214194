export default function () {
  return {
    BCA: {
      bankDataId: '9ad19cfe-48dc-11ea-b77f-2e728ce88125',
      bankName: 'HSBC',
      bankAccountName: 'Business Current Account (Free Banking Period is 18 Months for Start-Up or 12 Months for Switcher)',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 90.0,
      accountHighlights: ['Due to unprecedented demand HSBC are not currently accepting applications for new business bank accounts.'],
      accountFeatures: {
        freeBankingPeriod: 'Up to 18',
        accountFee: { amount: '£0', description: 'per month' },
        averageDaysToOpenAnAccountFee: '8',
        averageDaysToReceiveADebitCard: '3',
        averageDaysToSetupInternetBanking: '5',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: true,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'Paym',
            body:
              "Paym is a quick, easy and secure way to receive payments from your business customers using one of your registered mobile phone numbers. Improve your cash flow by receiving payments at point of sale or service delivery. Because they don't need to enter your bank details, you have the added security that customers will only see your business name and not your account number."
          },
          {
            header: '',
            body:
              'Fincancial Wellbeing Programme for businesses - We know you care about the mental and physical health of your employees but have you fully considered the impact their financial wellbeing may have on them. We believe it’s important that people have the insight and education they need to help them plan for significant life stages, which is why we now offer our Financial Wellbeing Programme to business customers.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: '',
            body:
              "International Business Guarantees - When you're trading internationally without established relationships, our guarantees reduce trade risks by giving you and your supplier financial security."
          },
          {
            header: '',
            body:
              'Export Letters of Credit - If you export goods, you can trade securely in the knowledge that you will receive payment - as long as you present documentation that satisfies the pre-agreed conditions.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 51,
        onlineAndMobileBankingService: 62,
        smeOverdraftAndLoanServices: 60,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 53,
        serviceInBranchesAndBusinessCenters: 50,
        description: 'Most recent results - accurate as of August 2019',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: 'Free'
          },
          {
            name: 'StandingOrder',
            value: 'Free'
          },
          {
            name: 'CardPurchaseDomestic',
            value: 'Free'
          },
          {
            name: 'CardPurchaseForeign',
            value: '2.75%'
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '2.75% of the converted amount & a withdrawal fee of 1.5% (min £1.75) of the converted amount',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: 'Free'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            value: 'Free'
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            value: 'Free'
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            tooltip: '£17 when done online, £30 when done in branch',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: '£17 when done online, £30 when done in branch',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            value: 'Free'
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: 'Up to £3000 free, then 0.5% of value deposited',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: 'Up to £3000 free, then 0.5% of value deposited',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: free, Foreign: £0.20 for SEPA, 0 for CHAPS',
            info: true
          }
        ],
        totalSavings: 90.0
      },
      links: {
        openAccount: 'https://www.business.hsbc.uk/en-gb/everyday-banking/business-accounts/start-up-business-bank-account',
        switchAccount: 'https://www.business.hsbc.uk/en-gb/everyday-banking/business-accounts/switching-business-bank-accounts'
      }
    }
  }
}
