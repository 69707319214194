import staticAIB from './AIB'
import staticBARCLAYS from './BARCLAYS'
import staticBOI from './BOI'
import staticBOS from './BOS'
import staticCOOP from './COOP'
import staticDANSKE from './DANSKE'
import staticHSBC from './HSBC'
import staticLLOYDS from './LLOYDS'
import staticMETRO from './METRO'
import staticNATWEST from './NATWEST'
import staticREVOLUT from './REVOLUT'
import staticRBS from './RBS'
import staticSANTANDER from './SANTANDER'
import staticSTARLING from './STARLING'
import staticTSB from './TSB'
import staticUB from './UB'

export default function(key) {
  const bankNameId = key.split('-')[0]
  const accountNameId = key.split('-')[1]
  switch (bankNameId) {
    case 'AIB':
      return staticAIB()[accountNameId]
    case 'BARCLAYS':
      return staticBARCLAYS()[accountNameId]
    case 'BOI':
      return staticBOI()[accountNameId]
    case 'BOS':
      return staticBOS()[accountNameId]
    case 'COOP':
      return staticCOOP()[accountNameId]
    case 'DANSKE':
      return staticDANSKE()[accountNameId]
    case 'HSBC':
      return staticHSBC()[accountNameId]
    case 'SANTANDER':
      return staticSANTANDER()[accountNameId]
    case 'REVOLUT':
      return staticREVOLUT()[accountNameId]
    case 'RBS':
      return staticRBS()[accountNameId]
    case 'METRO':
      return staticMETRO()[accountNameId]
    case 'NATWEST':
      return staticNATWEST()[accountNameId]
    case 'LLOYDS':
      return staticLLOYDS()[accountNameId]
    case 'STARLING':
      return staticSTARLING()[accountNameId]
    case 'TSB':
      return staticTSB()[accountNameId]
    case 'UB':
      return staticUB()[accountNameId]

    default:
      return {}
  }
}
