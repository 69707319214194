export default function () {
  return {
    BCA: {
      bankDataId: '7c7a1e00-48f2-11ea-b77f-2e728ce88125',
      bankName: 'Santander',
      bankAccountName: '1|2|3 Business Account',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -60.0,
      accountHighlights: ['Santander has temporarily stopped accepting applications for new Business Current Accounts'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£5', description: 'per month' },
        averageDaysToOpenAnAccountFee: '3',
        averageDaysToReceiveADebitCard: '7',
        averageDaysToSetupInternetBanking: '5',
        dedicatedRelationshipManager: true,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: true,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: '',
            body:
              'Be assured that your money is safe when you bank online. In the unlikely event funds are taken from your account as a result of fraud, we will refund you and restore your account to the state it would have been in had the unauthorised payment transaction not taken place.'
          },
          {
            header: '',
            body: "When you shop online, you may sometimes be asked to take an extra step to verify that it's you making your purchase using the mobile app or an OTP."
          },
          {
            header: '',
            body:
              'You can use our cash machines for mnay of your day-to-day transactions, including: withdrawing cash up to £500, paying money in at selected machines and checking your balance.'
          },
          { header: '', body: 'With 11,500 Post Office branches nationwide, you have a convenient place to: deposit cash and cheques, withdraw money and find out your balance.' }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          { header: '', body: 'Santander have been awarded Best Business Current Account Provider for the last 17 years by Moneyfacts.' },
          {
            header: 'Santander Breakthrough',
            body: 'We organise a range of events up and down the country where you can gain new knowledge, resolve problems and expand your horizons.'
          },
          {
            header: 'Marketing with Talent Cupboard',
            body:
              'Talent Cupboard provides digital marketing services to ambitious businesses. From pay monthly website packages through to bespoke builds and branding - their talented digital natives can help. Simply get in touch and they’ll provide a free consultation on which products are suited to help your business grow.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 64,
        onlineAndMobileBankingService: 70,
        smeOverdraftAndLoanServices: 65,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 64,
        serviceInBranchesAndBusinessCenters: 56,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: 'Free'
          },
          {
            name: 'StandingOrder',
            value: 'Free'
          },
          {
            name: 'CardPurchaseDomestic',
            value: 'Free'
          },
          {
            name: 'CardPurchaseForeign',
            value: '2.75%'
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            value: 'Free'
          },
          {
            name: 'ChequePaymentOut',
            value: 'Free'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            value: 'Free'
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: 'Per month - first £0.5k free; then £10 fee at 0.5k-2k; then 50p/£100 at £2-3k; then 45p/£100 at £3-5k; then 35p/£100 over £5k.',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            tooltip: 'Free for SEPA Transfer',
            info: true
          },
          {
            name: 'TransferForeignFast',
            value: '£25'
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip:
              'Free everyday banking for start-ups (if the business is in its first year of trading and this is its first business currnt account with Santander) for 18 months (£7.50 standard monthly fee thereafter)',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: 'Free for first £1000/month, then £0.7/£100',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: 'Free for first £1000/month, then £0.7/£100',
            info: true
          },
          {
            name: 'TransfersIn',
            value: 'Free'
          }
        ],
        totalSavings: -60.0
      },
      links: {
        openAccount: 'https://www.santander.co.uk/business/current-accounts/123-business-current-account',
        switchAccount: 'https://www.santander.co.uk/business/support/current-accounts/switching'
      }
    }
  }
}
