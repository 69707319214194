export default function () {
  return {
    BCA: {
      bankDataId: '600bc793-8613-4c0b-a747-5a4d6393892c',
      bankName: 'Rbs',
      bankAccountName: 'Business Current Account Type 40 (RBS)',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -369.64,
      accountHighlights: ['Due to Covid-19 account applications are currently taking longer than usual to process.'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£5', description: 'per month' },
        averageDaysToOpenAnAccountFee: '1',
        averageDaysToReceiveADebitCard: '3',
        averageDaysToSetupInternetBanking: '15',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 50000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          { header: '', body: 'Our mobile app is secure, convenient, easy to use and gives you all the tools you need to manage your business finances online.' },
          {
            header: 'Instantly freeze and unfreeze misplaced cards',
            body: "Misplaced your debit or credit card? Instantly lock your card with the app and stop it being used until it's found."
          },
          {
            header: '',
            body:
              'Community Bankers Community Bankers work in mostly rural communities. They’re committed to ensure you’re able to access banking services and educate the local area on fraud and scams protection.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          { header: 'FreeAgent', body: 'Free online accounting software for your business.' },
          {
            header: 'Mobile receipt management',
            body: 'Our receipt management service allows you to capture, track and manage all your receipts and expenses within the mobile banking app.'
          },
          {
            header: 'Exporting with DHL',
            body: "As a new RBS Business customer you'll be entitled to at least 60% off DHL Express' standard tariff for international shipping for 12 months."
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 41,
        onlineAndMobileBankingService: 62,
        smeOverdraftAndLoanServices: 50,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 46,
        serviceInBranchesAndBusinessCenters: 38,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: '35p'
          },
          {
            name: 'StandingOrder',
            value: '35p'
          },
          {
            name: 'CardPurchaseDomestic',
            value: '35p'
          },
          {
            name: 'CardPurchaseForeign',
            tooltip: '35p plus 2.75% (min £1) per item',
            info: true
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: '35p'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '35p plus 2.75% (minimum £1) per item plus 2% foreign cash fee (min £2, max £5) per item',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: '70p'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            tooltip: '70p per £100 per deposit',
            info: true
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: '70p per £100 per deposit',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            tooltip: '£10 for Europe via SEPA, £22 for RoW',
            info: true
          },
          {
            name: 'TransferForeignFast',
            value: '30p'
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: 'Free if transactions charges >£5 this month, £5 if charges <£5',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: '70p per £100 per deposit',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: '70p per £100 per deposit',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: 35p, Foreign: £1 for value less than £100, £7 for value above £100',
            info: true
          }
        ],
        totalSavings: -369.64
      },
      links: {
        openAccount: 'https://www.business.rbs.co.uk/business/bank-accounts/business-bank-account.html#readytoapply',
        switchAccount:
          'https://personal.rbs.co.uk/personal/current-accounts/switch-your-bank-account-to-rbs.html?extcam=R_ppc_GGL_MTA_MTAB_ZOM_rbs%20bank%20switch_Search&ds_rl=1007007&gclid=EAIaIQobChMIiM_S9cXT5wIVQbDtCh2dTAH3EAAYASABEgIr-fD_BwE&gclsrc=aw.ds'
      }
    }
  }
}
