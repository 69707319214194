export default function() {
  return {
    BA: {
      bankDataId: 'METRO-BA',
      bankName: 'Metro Bank',
      bankAccountName: 'Business (Daily Balance >£5k)',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -400.92,
      accountHighlights: ['#1 for Online and Mobile banking', 'MCash Handling Service', 'Acceptcards® Merchant services', 'Integrate with Xero'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£5', description: 'per month' },
        averageDaysToOpenAnAccountFee: '1',
        averageDaysToReceiveADebitCard: '2',
        averageDaysToSetupInternetBanking: '1',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          { header: 'MCash benefits:', body: 'Smart: Save time going to and from the bank. Have cash collected and deposited into your bank account.' },
          { header: '', body: 'Simple: Arrange and pay for your cash delivery and collection on the Metro Bank app.' },
          { header: '', body: 'Secure: Safely transport your cash. Collected cash insured from the moment it leaves your business premises.' },
          {
            header: 'Schedule payments and send them straight to Bacs',
            body: 'Schedule payments up to 70-days in advance and submit them either through online banking or straight to the Bacs clearing system.'
          },
          { header: 'We’re open 7 days a week', body: 'You don’t need an appointment – just walk into a store, and we’ll start opening your account' }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Acceptcards® Payment Services',
            body:
              'Metro Bank’s preferred partner when it comes to card payment services is acceptcards®. They’ll work with your business to find the best payment solutions whether you’re a small sole trader or a large corporate business. '
          },
          { header: 'Complete Control with Xero', body: 'Never miss a transaction or expense again by linking your bank account to your cloud-based Xero accounting software' },
          { header: 'Personal Service', body: 'Your Local Business Manager can help support your business, and you can talk to one of our Customer Support team 24/7' }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 68,
        onlineAndMobileBankingService: 73,
        smeOverdraftAndLoanServices: 0,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 65,
        serviceInBranchesAndBusinessCenters: 75,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            tooltip:
              'You receive 50 free transactions per month that can be any combination of: Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers out, Domestic FP transfers out, Domestic Transfers in and Foreign Transfers in. After this allowance, the fee is then 30p per item.',
            info: true
          },
          {
            name: 'StandingOrder',
            tooltip:
              'You receive 50 free transactions per month that can be any combination of: Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers out, Domestic FP transfers out, Domestic Transfers in and Foreign Transfers in. After this allowance, the fee is then 30p per item.',
            info: true
          },
          {
            name: 'CardPurchaseDomestic',
            tooltip:
              'You receive 50 free transactions per month that can be any combination of: Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers out, Domestic FP transfers out, Domestic Transfers in and Foreign Transfers in. After this allowance, the fee is then 30p per item.',
            info: true
          },
          {
            name: 'CardPurchaseForeign',
            tooltip:
              'If in EU, free for first 50 Transactions per month of Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers, Domestic FP transfers, Domestic Transfers In and Foreign Transfers, then £0.3 per item; if outside EU, 30p plus 2.99% of the value',
            info: true
          },
          {
            name: 'ATMWithdrawalDomestic',
            tooltip:
              'You receive 50 free transactions per month that can be any combination of: Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers out, Domestic FP transfers out, Domestic Transfers in and Foreign Transfers in. After this allowance, the fee is then 30p per item.',
            info: true
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip:
              'If in EU, free for first 50 Transactions per month of Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers, Domestic FP transfers, Domestic Transfers In and Foreign Transfers, then £0.3 per item; if outside EU, £1.50 plus 2.99% of the value',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            tooltip:
              'You receive 50 free transactions per month that can be any combination of: Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers out, Domestic FP transfers out, Domestic Transfers in and Foreign Transfers in. After this allowance, the fee is then 30p per item.',
            info: true
          },
          {
            name: 'CounterCashWithdrawalBranch',
            value: '0.50%'
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            value: 'N/A'
          },
          {
            name: 'TransferDomestic',
            tooltip:
              'You receive 50 free transactions per month that can be any combination of: Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers out, Domestic FP transfers out, Domestic Transfers in and Foreign Transfers in. After this allowance, the fee is then 30p per item.',
            info: true
          },
          {
            name: 'TransferDomesticFast',
            tooltip:
              'You receive 50 free transactions per month that can be any combination of: Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers out, Domestic FP transfers out, Domestic Transfers in and Foreign Transfers in. After this allowance, the fee is then 30p per item.',
            info: true
          },
          {
            name: 'TransferForeign',
            tooltip: '£10 for SEPA',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: '£25 for SWIFT',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            value: 'Free'
          },
          {
            name: 'CounterCashDepositBranch',
            value: '0.50%'
          },
          {
            name: 'CounterCashDepositPostOffice',
            value: '0.50%'
          },
          {
            name: 'TransfersIn',
            tooltip:
              'You receive 50 free transactions per month that can be any combination of: Direct Debits, Standing Orders, Card domestic, ATM domestic, Cheques out, Domestic transfers out, Domestic FP transfers out, Domestic Transfers in and Foreign Transfers in. After this allowance, the fee is then 30p per item.',
            info: true
          }
        ],
        totalSavings: -400.92
      },
      links: {
        openAccount: 'https://www.metrobankonline.co.uk/business/current-accounts/products/business-bank-account/',
        switchAccount: 'https://www.metrobankonline.co.uk/business/current-accounts/products/business-bank-account/'
      }
    },
    BB: {
      bankDataId: 'METRO-BB',
      bankName: 'Metro Bank',
      bankAccountName: 'Business (Daily Balance <£5k)',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -29.82,
      accountHighlights: ['#1 for Online and Mobile banking', 'MCash Handling Service', 'Acceptcards® Merchant services', 'Integrate with Xero'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£0', description: 'per month' },
        averageDaysToOpenAnAccountFee: '1',
        averageDaysToReceiveADebitCard: '2',
        averageDaysToSetupInternetBanking: '1',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          { header: 'MCash benefits:', body: 'Smart: Save time going to and from the bank. Have cash collected and deposited into your bank account.' },
          { header: '', body: 'Simple: Arrange and pay for your cash delivery and collection on the Metro Bank app.' },
          { header: '', body: 'Secure: Safely transport your cash. Collected cash insured from the moment it leaves your business premises.' },
          {
            header: 'Schedule payments and send them straight to Bacs',
            body: 'Schedule payments up to 70-days in advance and submit them either through online banking or straight to the Bacs clearing system.'
          },
          { header: 'We’re open 7 days a week', body: 'You don’t need an appointment – just walk into a store, and we’ll start opening your account' }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Acceptcards® Payment Services',
            body:
              'Metro Bank’s preferred partner when it comes to card payment services is acceptcards®. They’ll work with your business to find the best payment solutions whether you’re a small sole trader or a large corporate business. '
          },
          { header: 'Complete Control with Xero', body: 'Never miss a transaction or expense again by linking your bank account to your cloud-based Xero accounting software' },
          { header: 'Personal Service', body: 'Your Local Business Manager can help support your business, and you can talk to one of our Customer Support team 24/7' }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 68,
        onlineAndMobileBankingService: 73,
        smeOverdraftAndLoanServices: 0,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 65,
        serviceInBranchesAndBusinessCenters: 75,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: '30p'
          },
          {
            name: 'StandingOrder',
            value: '30p'
          },
          {
            name: 'CardPurchaseDomestic',
            value: '30p'
          },
          {
            name: 'CardPurchaseForeign',
            tooltip: 'If in EU, 30p per item; if outside EU, 30p plus 2.99% of the value',
            info: true
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: '30p'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: 'If in EU, 30p per item; if outside EU, 30p plus 2.99% of the value',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: '30p'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            value: '0.50%'
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            value: 'N/A'
          },
          {
            name: 'TransferDomestic',
            value: '30p'
          },
          {
            name: 'TransferDomesticFast',
            value: '30p'
          },
          {
            name: 'TransferForeign',
            tooltip: '£10 for SEPA',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: '£25 for SWIFT',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£5 per month',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            value: '0.50%'
          },
          {
            name: 'CounterCashDepositPostOffice',
            value: '0.50%'
          },
          {
            name: 'TransfersIn',
            value: '30p'
          }
        ],
        totalSavings: -29.82
      },
      links: {
        openAccount: 'https://www.metrobankonline.co.uk/business/current-accounts/products/business-bank-account/',
        switchAccount: 'https://www.metrobankonline.co.uk/business/current-accounts/products/business-bank-account/'
      }
    }
  }
}
