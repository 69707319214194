<template>
  <div class="bank-details-page">
    <div class="ui active inverted dimmer page-loader" v-if="!bankDetails">
      <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
    </div>
    <template v-else>
      <header class="product-header">
        <div class="ui container">
          <RoundImage class="box logo-bank" :class="[bankDetails.bankName.replace(/\s/g, '')]">
            <BankLogo :bankName="bankDetails.bankName" />
          </RoundImage>
          <div class="header-info m-l-25">
            <h2 class="m-b-5">{{ bankDetails.bankName }}</h2>
            <h3 class="m-b-0">{{ bankDetails.bankAccountName }}</h3>
          </div>
        </div>
      </header>
      <div class="bank-details-main-params">
        <div class="ui container">
          <div class="product-details-nav p-t-20">
            <router-link class="btn btn-back" :to="{ name: 'savings-banking' }">
              <i class="icon material-icons ui fsBig">keyboard_backspace</i>
              <span>Back</span>
            </router-link>
            <div class="links">
              <a v-if="bankDetails.links.switchAccount" class="btn btn-primary btn-sm" :href="bankDetails.links.switchAccount" target="_blank" rel="noopener" @click="switchOpenClickHandler('CTA Start Switch')">Start Switch</a>
              <label v-else class="btn btn-primary btn-sm disabled">Start Switch</label>
              <a v-if="bankDetails.links.openAccount" class="btn btn-primary btn-sm m-l-10" :href="bankDetails.links.openAccount" target="_blank" rel="noopener" @click="switchOpenClickHandler('CTA Open Account')">Open Account</a>
              <label v-else class="btn btn-primary btn-sm disabled">Open Account</label>
            </div>
          </div>
          <div class="product-details-summary around-xs m-b-20">
            <div class="row">
              <div class="box-container ui card col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="box">
                  <div class="h1">
                    {{ bankDetails.accountFeatures.accountFee.amount | numberSeparator }}
                  </div>
                  <div class="meta m-b-10">{{ bankDetails.accountFeatures.accountFee.description }}</div>
                  <div class="h2">Account Fee</div>
                </div>
              </div>
              <div class="box-container ui card col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="box">
                  <div class="placeholder"></div>
                  <div class="h2">You could save annually</div>
                </div>
              </div>
              <div class="box-container ui card col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="box">
                  <div class="h1" v-if="bankDetails.accountFeatures.overdraftLimited !== null">{{ bankDetails.accountFeatures.overdraftLimited | numberSeparator | currency }}</div>
                  <div class="h1" v-else>N/A</div>
                  <div class="h2">Overdraft limit</div>
                </div>
              </div>
              <div class="box-container ui card col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="box text-xs">
                  <ul class="account-highlights-list">
                    <li class="meta" v-for="(highlight, index) in bankDetails.accountHighlights" :key="index">{{ highlight }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="main-wrapper">
        <div class="ui stackable container features">
          <div class="ui secondary pointing menu tabs">
            <router-link
              class="item tab"
              :class="{ active: index === 0 || isCurrentRouteActive('static-bank-details-features-and-benefits', 0) }"
              :to="{ name: 'static-bank-details-features-and-benefits' }"
              @click.native="linkbankDetailsClickHandler(bankDetails.bankName, 'Features & Benefits')"
              >Features &amp; Benefits</router-link
            >
            <router-link
              class="item tab"
              :class="{ active: index === 1 || isCurrentRouteActive('static-bank-details-fee-and-tariffs', 1) }"
              :to="{ name: 'static-bank-details-fee-and-tariffs' }"
              @click.native="linkbankDetailsClickHandler(bankDetails.bankName, 'Fees & Tariffs')"
              >Charge rates</router-link
            >
          </div>
          <router-view :bankDetails="bankDetails" />
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import BankLogo from '@/components/BankLogo'
import RoundImage from '@/components/RoundImage'
import staticData from './static-bank-details-data/index'
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'BankDetails',
  components: {
    BankLogo,
    RoundImage
  },
  data() {
    return {
      bankDetails: null,
      index: 0
    }
  },
  computed: {
    bankAccountId() {
      return this.$route.params.bankAccountId
    },
    bankDataId() {
      return this.$route.params.bankDataId
    }
  },
  methods: {
    linkbankDetailsClickHandler(bankName, page) {
      const bankValue = `${bankName} ${page}`
      this.$ma.trackEvent({ category: 'CTA Bank Details', action: 'click', label: bankValue })
    },
    getPersonalisedComparisonReportClickHandler(bankAccountId, bankDataId = null) {
      this.$ma.trackEvent({
        category: 'CTA Get Personalised Comparison Report',
        action: 'click'
      })
      this.$modal.show('emailInputModal', { bankAccountId, bankDataId })
    },
    async getBankComparisonDetails() {
      const data = await new Promise(resolve => {
        return resolve(staticData(this.bankDataId))
      })
      return { data }
    },
    loadBankDetails() {
      return this.getBankComparisonDetails()
        .then(res => {
          this.bankDetails = res.data
          this.trackPageView()
        })
        .catch(e => {
          this.bankDetails = []
        })
    },
    init() {
      this.loadBankDetails()
    },
    setActiveIndex(index) {
      this.index = index
    },
    isCurrentRouteActive(routeName, index) {
      if (this.$route.name === routeName) {
        this.index = index
      }
      return this.$route.name === routeName
    },
    switchOpenClickHandler(switchOrOpen) {
      this.$ma.trackEvent({
        action: 'click',
        eventType: switchOrOpen,
        eventProperties: {
          bankName: this.bankDetails.bankName,
          bankAccountName: this.bankDetails.bankAccountName,
          currentBankingFeeAmount: this.bankDetails.currentBankingFeeAmount,
          bankingFeeAmountSave: this.bankDetails.bankingFeeAmountSave
        }
      })
    },
    trackPageView() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.pageViews.BANK_ACC_COMPA,
        eventProperties: {
          bankName: this.bankDetails.bankName,
          bankAccountName: this.bankDetails.bankAccountName,
          currentBankingFeeAmount: this.bankDetails.currentBankingFeeAmount,
          bankingFeeAmountSave: this.bankDetails.bankingFeeAmountSave
        }
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.bank-details-page {
  display: flex;
  flex-direction: column;
  .spinner {
    margin: 0 auto;
  }

  .placeholder {
    margin: 0 auto;
    height: 12px;
    width: 80px;
    border-radius: 4px;
    background-color: var(--color-primary-100);
    margin-bottom: 12px;
    display: block;
  }
  .product-details-summary {
    .row {
      .ui.card {
        margin: 0rem;
        border-radius: 2px;
        border-right: 1px solid $default-border-color;
        .meta {
          font-size: 12px;
          line-height: 16px;
        }
        .warning {
          color: var(--color-error-500);
        }
      }
      .box-container {
        height: 124px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        border-radius: $default-border-radius;
        box-shadow: $default-box-shadow;
        .h1 {
          display: flex;
          font-size: 1.75rem;
          font-weight: 400;
          margin-bottom: 0.25rem;
          .popover {
            margin-left: 0.5rem;
          }
        }
        .box {
          padding: 0rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        &:last-child {
          font-size: 12px;
        }
      }
    }
  }
}

.main-wrapper {
  flex-grow: 1;
  background: $color-white;
  .tabs {
    border-bottom: 1px solid var(--color-primary-100);
  }
}
.cash-back-info {
  padding: 15px 35px 0;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.5px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 15px 15px 0;
  }
}

.account-highlights-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.logo-bank {
  &.STARLING,
  &.STARLINGBANK {
    /deep/ {
      .logo-holder {
        background: #171237;
      }
    }
  }
}

.product-details-nav {
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 1rem;
  }
  button {
    &:last-child {
      color: var(--color-secondary-500);
    }
  }

  .btn-back {
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: $breakpoint-sm) {
    display: flex;
    flex-direction: column;
  }
  button {
    @media only screen and (max-width: $breakpoint-sm) {
      margin-bottom: 1rem;
    }
  }
}
.product-details-nav .links {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: $breakpoint-sm) {
    width: 100%;
    margin-bottom: 1rem;
  }
  label {
    margin-right: 1rem;
    @media only screen and (max-width: $breakpoint-sm) {
      margin-bottom: 1rem;
    }
  }
}

.ui.stackable.container.features {
  padding: 1rem;
}
</style>
