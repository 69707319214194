export default function() {
  return {
    SB: {
      bankDataId: 'DANSKE-SB',
      bankName: 'Danske Bank',
      bankAccountName: 'Danske Small Business',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -131.64,
      accountHighlights: [
        'Manage FX and Interest Rate risk',
        'Free Webroot SecureAnywhere® antivirus software',
        'Integrated security in Business Banking',
        'Preferential rate at the Post Office®'
      ],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£12.50', description: 'per month' },
        averageDaysToOpenAnAccountFee: '3',
        averageDaysToReceiveADebitCard: '5',
        averageDaysToSetupInternetBanking: '8',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: true,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'Download our Mobile App and access your account from the office, home or abroad.',
            body:
              'You can monitor your business banking accounts and approve transactions on your iPhone, iPad or Android device. You can create and authorise payments, view your account information and transaction history, send messages to your advisor and much more.'
          },
          { header: '', body: 'District is our online business banking platform for Danske business customers. One feature is Free Webroot SecureAnywhere® antivirus software.' },
          { header: 'Integrated security in Business eBanking ', body: 'Your data is encrypted during transmission between your browser and Danske Bank' }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Managing foreign exchange and interest rate risk',
            body: 'Talk to one of our team if you have foreign exchange or interest rate esposure that you wish to manage.'
          },
          { header: '', body: 'Preferential rate for lodgements made through Cash Drop and Post Office®' },
          { header: '', body: 'Manage your finances with our extensive range of digital banking solutions.' }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 61,
        onlineAndMobileBankingService: 72,
        smeOverdraftAndLoanServices: 59,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 66,
        serviceInBranchesAndBusinessCenters: 61,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: '5p'
          },
          {
            name: 'StandingOrder',
            value: '5p'
          },
          {
            name: 'CardPurchaseDomestic',
            value: '5p'
          },
          {
            name: 'CardPurchaseForeign',
            value: '2.75%'
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: '5p'
          },
          {
            name: 'ATMWithdrawalForeign',
            value: '2.75%'
          },
          {
            name: 'ChequePaymentOut',
            value: '82p'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            tooltip: '82p per withdrawal, plus £1.32 per £100 out',
            info: true
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: '82p per withdrawal, plus £1.32 per £100 out',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: '5p'
          },
          {
            name: 'TransferDomesticFast',
            value: '5p'
          },
          {
            name: 'TransferForeign',
            value: '£20'
          },
          {
            name: 'TransferForeignFast',
            value: '£22'
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£12.5 per month',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: '72p per lodgement, plus 82p per £100 lodged',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: '72p per lodgement, plus 82p per £100 lodged',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: 5p, Foreign: Free from another account within Dankse Bank Group, £7 per item otherwise',
            info: true
          }
        ],
        totalSavings: -131.64
      },
      links: {
        openAccount: 'https://danskebank.co.uk/business/our-teams/advisers/small-business',
        switchAccount: 'https://danskebank.co.uk/business/our-teams/advisers/small-business'
      }
    },
    LB: {
      bankDataId: 'be410226-48e9-11ea-b77f-2e728ce99125',
      bankName: 'Danske Bank',
      bankAccountName: 'Danske Large Business',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -540.14,
      accountHighlights: [
        'Manage FX and Interest Rate risk',
        'Free Webroot SecureAnywhere® antivirus software',
        'Integrated security in Business Banking',
        'Preferential rate at the Post Office®'
      ],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£6', description: 'per month' },
        averageDaysToOpenAnAccountFee: '3',
        averageDaysToReceiveADebitCard: '5',
        averageDaysToSetupInternetBanking: '8',
        dedicatedRelationshipManager: true,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: true,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'Download our Mobile App and access your account from the office, home or abroad.',
            body:
              'You can monitor your business banking accounts and approve transactions on your iPhone, iPad or Android device. You can create and authorise payments, view your account information and transaction history, send messages to your advisor and much more.'
          },
          { header: '', body: 'District is our online business banking platform for Danske business customers. One feature is Free Webroot SecureAnywhere® antivirus software.' },
          { header: 'Integrated security in Business eBanking ', body: 'Your data is encrypted during transmission between your browser and Danske Bank' }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Managing foreign exchange and interest rate risk',
            body: 'Talk to one of our team if you have foreign exchange or interest rate esposure that you wish to manage.'
          },
          { header: '', body: 'Preferential rate for lodgements made through Cash Drop and Post Office®' },
          { header: '', body: 'Manage your finances with our extensive range of digital banking solutions.' }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 61,
        onlineAndMobileBankingService: 72,
        smeOverdraftAndLoanServices: 59,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 66,
        serviceInBranchesAndBusinessCenters: 61,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          { name: 'DirectDebit', value: -136.0 },
          { name: 'StandingOrder', value: -18.0 },
          { name: 'CardPurchaseDomestic', value: -220.8 },
          { name: 'CardPurchaseForeign', value: 0.0 },
          { name: 'ATMWithdrawalDomestic', value: -0.8 },
          { name: 'ATMWithdrawalForeign', value: 0.0 },
          { name: 'ChequePaymentOut', value: 0.0 },
          { name: 'CounterCashWithdrawalBranch', value: 0.0 },
          { name: 'CounterCashWithdrawalPostOffice', value: -2.14 },
          { name: 'TransferDomestic', value: -5.2 },
          { name: 'TransferDomesticFast', value: -54.4 },
          { name: 'TransferForeign', value: 0.0 },
          { name: 'TransferForeignFast', value: 0.0 },
          { name: 'AccountMaintenanceFee', value: 18.0 },
          { name: 'CounterCashDepositBranch', value: 0.0 },
          { name: 'CounterCashDepositPostOffice', value: 0.0 },
          { name: 'TransfersIn', value: -120.8 }
        ],
        totalSavings: -540.14
      },
      links: { openAccount: 'https://danskebank.co.uk/business/our-teams/advisers/corporate', switchAccount: 'https://danskebank.co.uk/business/our-teams/advisers/corporate' }
    }
  }
}
