import dictionary from '@/config/dictionary'

export default function () {
  return {
    SA: {
      bankDataId: 'UB-SA',
      bankName: 'Ulster Bank (NI)',
      bankAccountName: `Startup Current Account (Free banking for 18 months if trading <12 months and ${dictionary.turnover} <£1m)`,
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 7.8,
      accountHighlights: ['Business Growth Enablers', 'Help boosting connections and partnerships', 'Business Development Managers', 'Internet Banking'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£8', description: 'per quarter' },
        averageDaysToOpenAnAccountFee: '1',
        averageDaysToReceiveADebitCard: '3',
        averageDaysToSetupInternetBanking: '15',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: '',
            body:
              'Our mobile app is the fast way to access your business accounts, it is easy to use and helps you manage your finances at a time and place that suits you. Keeping your finances secure is our number one priority - especially on our mobile app.'
          },
          {
            header: 'Mobile receipt management',
            body: 'Our receipt management service allows you to capture, track and manage all your receipts and expenses within the mobile banking app.'
          },
          {
            header: '',
            body: 'Our Anytime Internet Banking service gives you the flexibility to manage your money at any time 365 days a year, from the comfort of your own home.'
          },
          {
            header: 'Business Development Managers',
            body: 'Our Business Managers have years of experience in both agri and small business and can help to support your business growth and success.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Business Growth Enablers',
            body: 'Our Business Growth Enablers can help boost your insight and knowledge in specialist subjects by giving you access to local talks and educational events.'
          },
          {
            header: '',
            body:
              'Boost your connections with opportunities and partnerships. These could be within our group or through a variety of other businesses we work with - they could help you on the road to success.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 52,
        onlineAndMobileBankingService: 63,
        smeOverdraftAndLoanServices: 56,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 53,
        serviceInBranchesAndBusinessCenters: 52,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: 'Free'
          },
          {
            name: 'StandingOrder',
            value: 'Free'
          },
          {
            name: 'CardPurchaseDomestic',
            value: 'Free'
          },
          {
            name: 'CardPurchaseForeign',
            tooltip: '2.65% non-sterling transaction fee plus 75p per item',
            info: true
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '2.65% non-sterling transaction fee plus 2.25% of combined value of transaction and NSTF (min £2, max £5)',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: 'Free'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            value: 'Free'
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            value: 'Free'
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            value: 'Free'
          },
          {
            name: 'TransferForeignFast',
            value: '£25'
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£15 per quarter (due to £5 monthly bankline fee)',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            value: 'Free'
          },
          {
            name: 'CounterCashDepositPostOffice',
            value: 'Free'
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: Free, Foreign: £6 if sender is Ulster Bank customer, £15 otherwise',
            info: true
          }
        ],
        totalSavings: 7.8
      },
      links: {
        openAccount: 'https://digital.ulsterbank.co.uk/business/bank-accounts/startup.html',
        switchAccount: 'https://digital.ulsterbank.co.uk/business/bank-accounts/startup.html'
      }
    },
    BBA: {
      bankDataId: 'UB-BBA',
      bankName: 'Ulster Bank (NI)',
      bankAccountName: 'Business Bank Account',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -628.41,
      accountHighlights: ['Business Growth Enablers', 'Help boosting connections and partnerships', 'Business Development Managers', 'Internet Banking'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£8', description: 'per quarter' },
        averageDaysToOpenAnAccountFee: '1',
        averageDaysToReceiveADebitCard: '3',
        averageDaysToSetupInternetBanking: '15',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: '',
            body:
              'Our mobile app is the fast way to access your business accounts, it is easy to use and helps you manage your finances at a time and place that suits you. Keeping your finances secure is our number one priority - especially on our mobile app.'
          },
          {
            header: 'Mobile receipt management',
            body: 'Our receipt management service allows you to capture, track and manage all your receipts and expenses within the mobile banking app.'
          },
          {
            header: '',
            body: 'Our Anytime Internet Banking service gives you the flexibility to manage your money at any time 365 days a year, from the comfort of your own home.'
          },
          {
            header: 'Business Development Managers',
            body: 'Our Business Managers have years of experience in both agri and small business and can help to support your business growth and success.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: 'Business Growth Enablers',
            body: 'Our Business Growth Enablers can help boost your insight and knowledge in specialist subjects by giving you access to local talks and educational events.'
          },
          {
            header: '',
            body:
              'Boost your connections with opportunities and partnerships. These could be within our group or through a variety of other businesses we work with - they could help you on the road to success.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 52,
        onlineAndMobileBankingService: 63,
        smeOverdraftAndLoanServices: 56,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 53,
        serviceInBranchesAndBusinessCenters: 52,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: '43p'
          },
          {
            name: 'StandingOrder',
            value: '58p'
          },
          {
            name: 'CardPurchaseDomestic',
            value: '43p'
          },
          {
            name: 'CardPurchaseForeign',
            tooltip: '2.65% non-sterling transaction fee plus 75p per item',
            info: true
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '2.65% non-sterling transaction fee plus 2.25% of combined value of transaction and NSTF (min £2, max £5)',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: '58p'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            tooltip: '70p per £100',
            info: true
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: '70p per £100',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: '43p'
          },
          {
            name: 'TransferDomesticFast',
            value: '43p'
          },
          {
            name: 'TransferForeign',
            value: 'Free'
          },
          {
            name: 'TransferForeignFast',
            value: '£25'
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£23 per quarter (due to £5 monthly bankline fee)',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: '70p per £100',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: '70p per £100',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: 43p, Foreign: £6 if sender is Ulster Bank customer, £15 otherwise',
            info: true
          }
        ],
        totalSavings: -628.41
      },
      links: {
        openAccount: 'https://digital.ulsterbank.co.uk/business/bank-accounts/business-account.html',
        switchAccount: 'https://digital.ulsterbank.co.uk/business/bank-accounts/business-account.html'
      }
    }
  }
}
