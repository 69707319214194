import dictionary from '@/config/dictionary'

export default function () {
  return {
    BA: {
      bankDataId: 'BOS-BA',
      bankName: 'Bank of Scotland',
      bankAccountName: `Business Account (£0-3m ${dictionary.turnover})`,
      accountHighlights: ['International Trade Portal', 'Savings Strategy Tool', 'Telepay batch payment service', 'Mobile banking app'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£6.50', description: 'per month' },
        averageDaysToOpenAnAccountFee: '4',
        averageDaysToReceiveADebitCard: '5',
        averageDaysToSetupInternetBanking: '5',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: '',
            body:
              'Telepay is an outsourced service that is ideal if your business performs up to 150 Bacs transactions a month. It uses the Bacs system to process all your regular business payments, from weekly wages and monthly salaries to expenses and payments to suppliers. This simple, efficient automated service payments service can save you time and valuable resources. '
          },
          {
            header: 'Our mobile banking app is a great way to securely access and manage your business accounts on the move.',
            body:
              'With fingerprint and Face ID whether you are heading to your next meeting, in the office without your PC or on your way home it is even easier to manage your finances on our app.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: '',
            body:
              'The International Trade Portal is a powerful insight platform, with practical support that helps you trade internationally with your chosen market. More than 25,000 market reports for your business oversea.'
          },
          {
            header: 'Savings Strategy Tool',
            body:
              'Make your money work harder by matching your cash forecasts against Bank of Scotland deposit products, helping you create a savings strategy bespoke to your needs.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 49,
        onlineAndMobileBankingService: 58,
        smeOverdraftAndLoanServices: 53,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 48,
        serviceInBranchesAndBusinessCenters: 52,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          { name: 'DirectDebit', value: 'Free' },
          { name: 'StandingOrder', value: 'Free' },
          { name: 'CardPurchaseDomestic', value: 'Free' },
          { name: 'CardPurchaseForeign', tooltip: '2.75% Non-Sterling Transaction Fee', info: true },
          { name: 'ATMWithdrawalDomestic', value: 'Free' },
          { name: 'ATMWithdrawalForeign', tooltip: '2.75% NSTF & 1.5% Foreign Cash Fee ', info: true },
          { name: 'ChequePaymentOut', value: '65p' },
          { name: 'CounterCashWithdrawalBranch', tooltip: '£1 per £100 for first £1500/month, then £0.8 per £100', info: true },
          { name: 'CounterCashWithdrawalPostOffice', tooltip: '£1 per £100 for first £1500/month, then £0.8 per £100', info: true },
          { name: 'TransferDomestic', value: 'Free' },
          { name: 'TransferDomesticFast', value: 'Free' },
          {
            name: 'TransferForeign',
            tooltip:
              'Internet Banking - £15 for EU payments, £27 if Zone 1 payments (including US, Canada, Switzerland, Monaco, San Marino, Jersey, Guernsey and Isle of Man), £35 if Zone 2 (RoW). In branch - £21 for EU payments, £33 if Zone 1, £41 if Zone 2 (RoW).',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip:
              'Internet Banking - £15 for EU payments, £27 if Zone 1 payments (including US, Canada, Switzerland, Monaco, San Marino, Jersey, Guernsey and Isle of Man), £35 if Zone 2 (RoW). In branch - £28 for EU payments, £40 if Zone 1 payments (including US, Canada, Switzerland, Monaco, San Marino, Jersey, Guernsey and Isle of Man), £48 if Zone 2 (RoW).',
            info: true
          },
          { name: 'AccountMaintenanceFee', value: '£6.50' },
          {
            name: 'CounterCashDepositBranch',
            tooltip: '£1 per £100 for first £1500/month, then £0.8 per £100',
            info: true
          },
          { name: 'CounterCashDepositPostOffice', tooltip: '£1 per £100 for first £1500/month, then £0.8 per £100', info: true },
          { name: 'DomesticTransfersIn', value: 'Free' },
          { name: 'ForeignTransfersIn', tooltip: '£2 per item if value <=£100, £7 if value >£100', info: true }
        ],
        totalSavings: 11.0
      },
      links: { openAccount: 'https://businessonboarding.bankofscotland.co.uk/Splash', switchAccount: 'https://businessonboarding.bankofscotland.co.uk/Splash' }
    },
    CT: {
      bankDataId: 'BOS-CT',
      bankName: 'Bank of Scotland',
      bankAccountName: `Business Account (£3-25m ${dictionary.turnover})`,
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: -483.53,
      accountHighlights: ['International Trade Portal', 'Savings Strategy Tool', 'Telepay batch payment service', 'Mobile banking app'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£6.50', description: 'per month' },
        averageDaysToOpenAnAccountFee: '4',
        averageDaysToReceiveADebitCard: '5',
        averageDaysToSetupInternetBanking: '5',
        dedicatedRelationshipManager: true,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: true,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: '',
            body:
              'Telepay is an outsourced service that is ideal if your business performs up to 150 Bacs transactions a month. It uses the Bacs system to process all your regular business payments, from weekly wages and monthly salaries to expenses and payments to suppliers. This simple, efficient automated service payments service can save you time and valuable resources. '
          },
          {
            header: 'Our mobile banking app is a great way to securely access and manage your business accounts on the move.',
            body:
              'With fingerprint and Face ID whether you are heading to your next meeting, in the office without your PC or on your way home it is even easier to manage your finances on our app.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: '',
            body:
              'The International Trade Portal is a powerful insight platform, with practical support that helps you trade internationally with your chosen market. More than 25,000 market reports for your business oversea.'
          },
          {
            header: 'Savings Strategy Tool',
            body:
              'Make your money work harder by matching your cash forecasts against Bank of Scotland deposit products, helping you create a savings strategy bespoke to your needs.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 49,
        onlineAndMobileBankingService: 58,
        smeOverdraftAndLoanServices: 53,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 48,
        serviceInBranchesAndBusinessCenters: 52,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          { name: 'DirectDebit', value: '43p' },
          { name: 'StandingOrder', value: '5p' },
          { name: 'CardPurchaseDomestic', value: '44p' },
          { name: 'CardPurchaseForeign', tooltip: '2.75% Non-Sterling Transaction Fee', info: true },
          { name: 'ATMWithdrawalDomestic', value: '42p' },
          { name: 'ATMWithdrawalForeign', tooltip: '2.75% NSTF & 1.5% Foreign Cash Fee', info: true },
          { name: 'ChequePaymentOut', value: '62p' },
          { name: 'CounterCashWithdrawalBranch', tooltip: '56p per £100', info: true },
          { name: 'CounterCashWithdrawalPostOffice', tooltip: '56p per £100', info: true },
          { name: 'TransferDomestic', value: '25p' },
          { name: 'TransferDomesticFast', value: '25p' },
          {
            name: 'TransferForeign',
            tooltip:
              'Internet Banking - £15 for EU payments, £27 if Zone 1 payments (including US, Canada, Switzerland, Monaco, San Marino, Jersey, Guernsey and Isle of Man), £35 if Zone 2 (RoW). In branch - £21 for EU payments, £33 if Zone 1, £41 if Zone 2 (RoW).',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip:
              'Internet Banking - £15 for EU payments, £27 if Zone 1 payments (including US, Canada, Switzerland, Monaco, San Marino, Jersey, Guernsey and Isle of Man), £35 if Zone 2 (RoW). In branch - £28 for EU payments, £40 if Zone 1 payments (including US, Canada, Switzerland, Monaco, San Marino, Jersey, Guernsey and Isle of Man), £48 if Zone 2 (RoW).',
            info: true
          },
          { name: 'AccountMaintenanceFee', value: '£6.50' },
          { name: 'CounterCashDepositBranch', tooltip: '56p per £100', info: true },
          { name: 'CounterCashDepositPostOffice', tooltip: '56p per £100', info: true },
          { name: 'DomesticTransfersIn', value: '15p' },
          { name: 'ForeignTransfersIn', tooltip: '£2 per item if value <=£100, £7 if value >£100', info: true }
        ],
        totalSavings: -483.53
      },
      links: {
        openAccount: 'https://business.bankofscotland.co.uk/3m-25m-turnover/business-accounts/business-account-for-switchers.html',
        switchAccount: 'https://business.bankofscotland.co.uk/3m-25m-turnover/business-accounts/business-account-for-switchers.html'
      }
    }
  }
}
