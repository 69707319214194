export default function() {
  return {
    BA: {
      bankDataId: 'STARLING-BA',
      bankName: 'STARLING BANK',
      bankAccountName: 'Business Current Account',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 139.05,
      accountHighlights: ['#1 Current Account 2019', 'Real-time banking', 'Spending Insights', 'Card Lock'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: { amount: '£0', description: 'per month' },
        averageDaysToOpenAnAccountFee: '1',
        averageDaysToReceiveADebitCard: '3',
        averageDaysToSetupInternetBanking: '1',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 150000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: false,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: true,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: false, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: 'The euro account',
            body:
              'Got a good thing going with Europe? If you’ve got financial commitments overseas, you can now hold, send and receive euros for free with a Starling Bank euro account.'
          },
          {
            header: 'Send money abroad',
            body:
              'No need for extra apps or services; instead, send fast and transparently priced money transfers to over 35 countries worldwide. Plus it’s cheaper than most high-street banks.'
          },
          {
            header: 'Card-free spending',
            body: 'Connect your account to the digital wallet of your choice, from Apple Pay and Google Pay to Samsung Pay, Fitbit Pay or Garmin Pay.'
          },
          { header: 'Statements', body: 'View or download statements month-by-month, or create your own custom statements in a few taps in the app.' }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          { header: 'Real-time banking', body: 'Know exactly what’s coming in and out of your bank account with instant payment notifications and a real time balance.' },
          {
            header: 'Understand how you spend',
            body: 'Spending Insights gives you a breakdown of your spending habits so you can budget better and understand exactly where your money goes.'
          },
          {
            header: 'Card lock',
            body: 'Lock your card in-app with a single tap if you think you’ve lost it, unlock it if you find it again, or cancel it with a tap if it’s gone for good.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 0,
        onlineAndMobileBankingService: 0,
        smeOverdraftAndLoanServices: 0,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 0,
        serviceInBranchesAndBusinessCenters: 0,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          { name: 'DirectDebit', value: 'Free' },
          { name: 'StandingOrder', value: 'Free' },
          { name: 'CardPurchaseDomestic', value: 'Free' },
          { name: 'CardPurchaseForeign', value: 'Free' },
          { name: 'ATMWithdrawalDomestic', value: 'Free' },
          { name: 'ATMWithdrawalForeign', value: 'Free' },
          { name: 'ChequePaymentOut', value: 'N/A' },
          { name: 'CounterCashWithdrawalBranch', value: 'N/A' },
          { name: 'CounterCashWithdrawalPostOffice', tooltip: '£0.5 per withdrawal', info: true },
          { name: 'TransferDomestic', value: 'Free' },
          { name: 'TransferDomesticFast', value: 'Free' },
          { name: 'TransferForeign', tooltip: '0.4% & country specific fee @ https://www.starlingbank.com/send-money-abroad/country-fees/', info: true },
          { name: 'TransferForeignFast', value: '£5.5' },
          { name: 'AccountMaintenanceFee', value: 'Free' },
          { name: 'CounterCashDepositBranch', value: 'N/A' },
          { name: 'CounterCashDepositPostOffice', tooltip: '£3 if value <£1k, 0.3% if value >=£1k', info: true },
          { name: 'TransfersIn', value: 'Free' }
        ],
        totalSavings: 139.05
      },
      links: {
        openAccount: 'https://www.starlingbank.com/campaign/business/business-current-account/?ko_click_id=ko_zkztehsv0idmq38cm',
        switchAccount: 'https://www.starlingbank.com/campaign/business/business-current-account/?ko_click_id=ko_zkztehsv0idmq38cm'
      }
    }
  }
}
