export default function() {
  return {
    BP: {
      bankDataId: 'TSB-BP',
      bankName: 'TSB',
      bankAccountName: 'Business Plus Account',
      currentBankingFeeAmount: 139.55,
      bankingFeeAmountSave: 29.3,
      accountHighlights: ['Free day-to-day banking', 'Enterprise Nation Support', 'Free Square payments reader', 'Access to instant access savings account'],
      accountFeatures: {
        freeBankingPeriod: 'Up to 25',
        accountFee: { amount: '£5', description: 'per month' },
        averageDaysToOpenAnAccountFee: '3',
        averageDaysToReceiveADebitCard: '7',
        averageDaysToSetupInternetBanking: '1',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000.0
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: { inBranch: true, online: true, telephone: true, postOffice: true, mobileApp: true },
      additionalWaysToBank: {
        description: [
          {
            header: '',
            body:
              'Manage your account through our Mobile App, Internet Bank, telephone or our network of over 500 TSB branches. You can also deposit cash and cheques at 11,500 Post Office Branches'
          },
          { header: '', body: 'Free Square Reader, so you can take chip & PIN and contactless payments on the go. Pay no fees on your first £1,000 of sales.' },
          { header: '', body: 'Our Business Debit Card allows you to withdraw money from an ATM and you pay no fees on transactions using your Debit Card' },
          {
            header: 'Verified by Visa and Mastercard SecureCode',
            body:
              'Providing you with extra security when you buy online using your TSB Business Debit Card and Credit Card. This means that you can safely purchase online, with fewer interruptions, knowing that Verified by Visa and Mastercard® SecureCode are helping protect you from fraud. '
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          { header: '', body: "25 months free day to day banking - so long as you keep your account within agreed limits and don't go overdrawn without arranging it first" },
          { header: '', body: 'Specialist support from Enterprise Nation - helping you grow your business' },
          { header: '', body: 'Access to our 1% Gross/AER interest (variable) instant access savings account' }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 44,
        onlineAndMobileBankingService: 43,
        smeOverdraftAndLoanServices: 47,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 46,
        serviceInBranchesAndBusinessCenters: 57,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: 'Free'
          },
          {
            name: 'StandingOrder',
            value: 'Free'
          },
          {
            name: 'CardPurchaseDomestic',
            value: 'Free'
          },
          {
            name: 'CardPurchaseForeign',
            value: '2.75%'
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: 'Free'
          },
          {
            name: 'ATMWithdrawalForeign',
            value: '2.75%'
          },
          {
            name: 'ChequePaymentOut',
            value: '70p'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            tooltip: '70p per £100',
            info: true
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: '70p per £100',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: 'Free'
          },
          {
            name: 'TransferDomesticFast',
            value: 'Free'
          },
          {
            name: 'TransferForeign',
            tooltip: '£15 via internet banking, £19 non-urgent euro, £21 International in branch',
            info: true
          },
          {
            name: 'TransferForeignFast',
            value: '£28'
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: 'free for the first 25 months, then still free if average balance >=£10k, costs £5 per month otherwise',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            ooltip: '70p per £100',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            ooltip: '70p per £100',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: Free, Foreign: £2 if value <= £100, £7 if value >£100',
            info: true
          }
        ],
        totalSavings: 29.3
      },
      links: { openAccount: 'https://www.tsb.co.uk/business/accounts/business_plus_account/', switchAccount: 'https://www.tsb.co.uk/business/accounts/business_plus_account/' }
    }
  }
}
