export default function() {
  return {
    BCA: {
      bankDataId: 'AIB-BCA',
      bankName: 'AIB (GB)',
      bankAccountName: 'Business Current Account',
      currentBankingFeeAmount: '',
      bankingFeeAmountSave: '',
      accountHighlights: ['iBusiness Banking', 'Cash in Transit service', 'Virtual Account Manager (VAM)', 'Lodge cash at other Banks'],
      accountFeatures: {
        freeBankingPeriod: '0',
        accountFee: {
          amount: '£10',
          description: 'per quarter'
        },
        averageDaysToOpenAnAccountFee: '10',
        averageDaysToReceiveADebitCard: '7',
        averageDaysToSetupInternetBanking: '23',
        dedicatedRelationshipManager: false,
        overdraftFacility: true,
        overdraftLimited: 25000
      },
      waysToPay: {
        atm: true,
        debitCard: true,
        creditCard: true,
        chargeCard: false,
        cheque: true,
        directDebit: true,
        standingOrder: true,
        applePay: true,
        googlePay: true,
        samsungPay: false,
        additionalDescriptiveText: ''
      },
      waysToBank: {
        inBranch: true,
        online: true,
        telephone: true,
        postOffice: true,
        mobileApp: false
      },
      additionalWaysToBank: {
        description: [
          {
            header: '',
            body:
              'iBusiness Banking (iBB), the internet-based payments and cash management system from Allied Irish Bank (GB), has been developed to make routine business banking more efficient; saving you time and money.'
          },
          {
            header: '',
            body:
              'At Allied Irish Bank (GB) we provide a range of options to meet the cash banking requirements of our trading business customers. If your business manages large cash volumes you can avail of Cash in Transit arrangements to deliver your cash directly to Cash Centres.'
          },
          {
            header: '',
            body:
              'Virtual Account Manager (VAM) is the latest addition to our iBusiness Banking (iBB) cash management system, allowing you to directly administer client and third party funds from your desktop PC using your web browser.'
          }
        ]
      },
      bankDataSwitchingService: {
        additionalAccountFeatures: false,
        localRelationshipManagers: false,
        description: [
          {
            header: '',
            body: 'Lodge cash over the counter at certain Allied Irish Bank (GB) Business Centre, via Post Offices or other Banks (by prior arrangement).'
          },
          {
            header: '',
            body:
              'An Allied Irish Bank (GB) Business Debit Card will allow the holder of the Card to make purchases worldwide in stores, over the phone or online from a Business Bank Account anywhere Visa Debit is accepted.'
          }
        ]
      },
      businessBankingServiceQuality: {
        overallService: 45,
        onlineAndMobileBankingService: 43,
        smeOverdraftAndLoanServices: 55,
        smeOverdraftAndLoanServicesDetails: '',
        relationshipAccountManagement: 55,
        serviceInBranchesAndBusinessCenters: 35,
        description: 'Most recent results - accurate as of February 2020',
        isPending: null,
        descriptionForPending: null
      },
      savingFees: {
        savings: [
          {
            name: 'DirectDebit',
            value: '55p'
          },
          {
            name: 'StandingOrder',
            value: '55p'
          },
          {
            name: 'CardPurchaseDomestic',
            value: '55p'
          },
          {
            name: 'CardPurchaseForeign',
            tooltip: '2.75% NSTF & 1.5% Cash fee if not Euro',
            info: true
          },
          {
            name: 'ATMWithdrawalDomestic',
            value: '55p'
          },
          {
            name: 'ATMWithdrawalForeign',
            tooltip: '2.75% NSTF & 1.5% Cash fee if not Euro',
            info: true
          },
          {
            name: 'ChequePaymentOut',
            value: '70p'
          },
          {
            name: 'CounterCashWithdrawalBranch',
            tooltip: '55p per item & 65p per £100',
            info: true
          },
          {
            name: 'CounterCashWithdrawalPostOffice',
            tooltip: '55p per item & 65p per £100',
            info: true
          },
          {
            name: 'TransferDomestic',
            value: '65p'
          },
          {
            name: 'TransferDomesticFast',
            value: '65p'
          },
          {
            name: 'TransferForeign',
            tooltip: 'Value <£15k = £20. Value >£15k = £35.',
            info: true
          },
          {
            name: 'TransferForeignFast',
            tooltip: '£35 per item for SWIFT',
            info: true
          },
          {
            name: 'AccountMaintenanceFee',
            tooltip: '£10 per quarter',
            info: true
          },
          {
            name: 'CounterCashDepositBranch',
            tooltip: '35p per item & 65p per £100',
            info: true
          },
          {
            name: 'CounterCashDepositPostOffice',
            tooltip: '35p per item & 65p per £100',
            info: true
          },
          {
            name: 'TransfersIn',
            tooltip: 'Domestic: 35p, Foreign: £5',
            info: true
          }
        ],
        totalSavings: -697.09
      },
      links: {
        openAccount: 'https://business.aib.ie/products/current-accounts/business-account-opening-form',
        switchAccount: 'https://business.aib.ie/products/current-accounts/business-account-opening-form'
      }
    }
  }
}
